import { createSlice } from '@reduxjs/toolkit';

import {
    getCustomerLifecycleMasterLevel,
    getLatestReportDownloadLink,
} from './thunks';
const initialState = {
    path: '',
    reportPath: '',
    loanRenewalPath: '',
    loanRenewalCheckerPath: '',
    masterData: {
        customerLifecycle: {},
    },
    activeCustomer: {},
    notificationReportDownloadData: [],
    isModalOpen: false,
    excelFiles: [],
    selectedLoanRenewalTab: 0,
    otp: '',
    otpExpiration: 60,
    isOtpExpired: false,
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        clearGlobaldata: (state) => {
            state.path = '';
            state.reportPath = '';
            state.loanRenewalPath = '';
            state.loanRenewalCheckerPath = '';
            state.otp = '';
            state.otpExpiration = 60;
            state.isOtpExpired = false;
        },
        navigatePath: (state, action) => {
            state.path = action.payload;
        },
        setSelectedCustomer: (state, action) => {
            state.activeCustomer = action.payload;
        },
        reportsPath(state, action) {
            state.reportPath = action.payload;
        },
        setOtp(state, action) {
            state.otp = action.payload;
        },
        setOtpExpiration: (state, action) => {
            state.otpExpiration = action.payload;
        },
        setIsOtpExpired: (state, action) => {
            state.isOtpExpired = action.payload;
        },
        loanRenewalPath(state, action) {
            state.loanRenewalPath = action.payload;
        },
        loanRenewalCheckerPath(state, action) {
            state.loanRenewalCheckerPath = action.payload;
        },
        setModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
        setExcelFiles: (state, action) => {
            state.excelFiles = action.payload;
        },
        resetExcelFiles: (state) => {
            state.excelFiles = [];
        },
        setSelectedLoanRenewalTab: (state, action) => {
            state.selectedLoanRenewalTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerLifecycleMasterLevel.fulfilled, (state, action) => {
                state.masterData.customerLifecycle = action?.payload?.data;
            })
            .addCase(getLatestReportDownloadLink.fulfilled, (state, action) => {
                state.notificationReportDownloadData = action?.payload?.data;
            })
            .addCase('global/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    navigatePath,
    clearGlobaldata,
    setSelectedCustomer,
    reportsPath,
    setOtp,
    setOtpExpiration,
    setIsOtpExpired,
    loanRenewalPath,
    loanRenewalCheckerPath,
    setModalOpen,
    setExcelFiles,
    resetExcelFiles,
    setSelectedLoanRenewalTab,
} = globalSlice.actions;

export default globalSlice.reducer;
