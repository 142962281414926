/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tab, Tabs, Tooltip } from '@mui/material';

import {
    selectLoanRenewalCheckerData,
    selectLoanRenewalData,
    selectReportData,
} from '../../src/redux/selector.js';
import { allRoleCodes, appLevelRoles } from '../appConfig';
import AuditIcon from '../assets/svg/SidebarIcons/Audit';
import DashboardIcon from '../assets/svg/SidebarIcons/Dashboard';
import LoanRenewalIcon from '../assets/svg/SidebarIcons/LoanRenewalIcon.jsx';
import ProcessLoanIcon from '../assets/svg/SidebarIcons/ProcessLoan';
import ReportsIcon from '../assets/svg/SidebarIcons/Reports';
import RetriggerIcon from '../assets/svg/SidebarIcons/Retrigger';
import {
    filterChipsClearAll,
    resetPagination,
    updatePayloadWithPath,
} from '../features/filter/redux/slice';
import { clearGroupListData } from '../features/loan-approval/redux/slice.js';
import { selectSelectedProduct, userInfo } from '../features/login/redux/selector';
import { navigatePath, reportsPath, setSelectedCustomer } from '../redux/slice';
import { routeConstant } from '../routes/routeConstant';
import { theme } from '../theme.js';

const tabDiv2 = {
    zIndex: '1',
    display: 'flex',
};

const tabStyle = {
    minWidth: '0.5rem',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px',
    '&.Mui-selected': {
        backgroundColor: 'transparent',
    },
};

const customIndicatorStyle = {
    backgroundColor: theme.palette.secondary.main,
    width: '70%',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '8px',
};

const hasLoanRenewalAccess = (appLevelRole = []) => {
    return appLevelRole?.some((role) => ['LR-MAKER', 'LR-CHECKER'].includes(role));
};

const Sidebar = React.memo(() => {
    const location = useLocation();
    const mainRoute = location.pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(userInfo);
    const reportPath = useSelector(selectReportData);
    const loanRenewalPath = useSelector(selectLoanRenewalData);
    const loanRenewalCheckerPath = useSelector(selectLoanRenewalCheckerData);
    const selectedProduct = useSelector(selectSelectedProduct);

    const [valueIndex, setValueIndex] = useState({});

    const isInLoanRenewalMode = userData?.appLevelRole?.some((role) =>
        ['LR-MAKER', 'LR-CHECKER'].includes(role)
    );

    const isAuthenticated = (roleCode, roles) => {
        if (
            userData?.roleCode === 'FEDLRA' &&
            userData?.appLevelRole?.includes('LR-CHECKER')
        ) {
            if (selectedProduct === 'PRAGATI_TERM_LOAN') {
                const specialAccessItems = ['Dashboard', 'Process Loan', 'Reports'];
                if (specialAccessItems.includes(roleCode)) {
                    return true;
                }
                return false;
            } else {
                return (
                    roles?.includes('FEDLRA') &&
                    appLevelRoles?.includes('LR-CHECKER')
                );
            }
        }
        if (
            userData?.appLevelRole?.includes('LR-MAKER') &&
            !userData?.appLevelRole?.includes('LR-CHECKER')
        ) {
            return roles?.includes('LR-MAKER');
        }
        if (roles?.includes('LR-MAKER') || roles?.includes('LR-CHECKER')) {
            return hasLoanRenewalAccess(userData?.appLevelRole);
        }
        return roleCode && roles?.includes(roleCode);
    };

    const setThemeOnSelect = (pathName) => {
        return mainRoute.includes(pathName)
            ? theme.palette.primary.main
            : theme.palette.common.grey;
    };

    const tabData = [
        {
            title: 'Dashboard',
            path: `/${routeConstant.DASHBOARD}`,
            icon: (
                <DashboardIcon
                    stroke={setThemeOnSelect(`/${routeConstant.DASHBOARD}`)}
                    fill={
                        mainRoute === '/dashboard'
                            ? 'none'
                            : theme.palette.common.white
                    }
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.dashboardRoles,
            productSpecific: {
                PRAGATI_TERM_LOAN: true,
                AGRI_LOAN: !isInLoanRenewalMode,
                specialAccess: (role, appLevelRole, selectedProduct) =>
                    role === 'FEDLRA' &&
                    appLevelRole?.includes('LR-CHECKER') &&
                    selectedProduct === 'PRAGATI_TERM_LOAN',
            },
            loanRenewalVisible: false,
        },
        {
            title: 'Customer Dashboard',
            path: `/${routeConstant.CUSTOMER_DASHBOARD}`,
            icon: (
                <ProcessLoanIcon
                    fill={setThemeOnSelect(`/${routeConstant.CUSTOMER_DASHBOARD}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.customerDashboardRoles,
            productSpecific: {
                PRAGATI_TERM_LOAN: false,
                AGRI_LOAN: !isInLoanRenewalMode,
            },
        },
        {
            title: 'Process Loan',
            path: `/${routeConstant.LOAN_APPROVAL}`,
            icon: (
                <ProcessLoanIcon
                    fill={setThemeOnSelect(`/${routeConstant.LOAN_APPROVAL}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.ProcessLoanRoles,
            productSpecific: {
                PRAGATI_TERM_LOAN: true,
                AGRI_LOAN: !isInLoanRenewalMode && userData?.partnerId !== 'SH-FED',
                specialAccess: (role, appLevelRole, selectedProduct) =>
                    role === 'FEDLRA' &&
                    appLevelRole?.includes('LR-CHECKER') &&
                    selectedProduct === 'PRAGATI_TERM_LOAN',
            },
        },
        {
            title: 'Reports',
            path: reportPath,
            icon: (
                <ReportsIcon
                    fill={setThemeOnSelect(`/${routeConstant.REPORTS}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.reportRoles,
            productSpecific: {
                PRAGATI_TERM_LOAN: true,
                AGRI_LOAN:
                    !isInLoanRenewalMode ||
                    (userData?.roleCode === 'FEDLRA' &&
                        userData?.appLevelRole?.includes('LR-CHECKER')),
                specialAccess: (role, appLevelRole, selectedProduct) =>
                    role === 'FEDLRA' &&
                    appLevelRole?.includes('LR-CHECKER') &&
                    selectedProduct === 'PRAGATI_TERM_LOAN',
            },
        },
        {
            title: 'Audit',
            path: `/${routeConstant.AUDIT}`,
            icon: (
                <AuditIcon
                    fill={setThemeOnSelect(`/${routeConstant.AUDIT}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.auditRoles,
            productSpecific: {
                PRAGATI_TERM_LOAN: true,
                AGRI_LOAN: !isInLoanRenewalMode,
            },
        },
        {
            title: 'Customer Technical Support',
            path: `/${routeConstant.RETRIGGER}`,
            icon: (
                <RetriggerIcon
                    fill={setThemeOnSelect(`/${routeConstant.RETRIGGER}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.retriggerRoles,
            productSpecific: {
                PRAGATI_TERM_LOAN: true,
                AGRI_LOAN: !isInLoanRenewalMode,
            },
        },
    ];

    if (userData?.appLevelRole?.includes('LR-MAKER')) {
        tabData.push({
            title: 'Loan Renewal (Maker)',
            path: loanRenewalPath,
            icon: (
                <LoanRenewalIcon
                    fill={setThemeOnSelect(`/${routeConstant.LOAN_RENEWAL}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.loanRenewal,
            productSpecific: {
                PRAGATI_TERM_LOAN: false,
                AGRI_LOAN: true,
            },
        });
    }

    if (userData?.appLevelRole?.includes('LR-CHECKER')) {
        tabData.push({
            title: 'Loan Renewal (Checker)',
            path: loanRenewalCheckerPath,
            icon: (
                <LoanRenewalIcon
                    fill={setThemeOnSelect(`/${routeConstant.LOAN_RENEWAL_CHECKER}`)}
                    width={30}
                    height={30}
                />
            ),
            role: allRoleCodes.loanRenewalChecker,
            productSpecific: {
                PRAGATI_TERM_LOAN: false,
                AGRI_LOAN: true,
            },
        });
    }

    // Filter tabs based on authentication
    const filteredTabs = tabData.filter((tab) => {
        if (
            userData?.roleCode === 'FEDLRA' &&
            userData?.appLevelRole?.includes('LR-CHECKER') &&
            selectedProduct === 'PRAGATI_TERM_LOAN'
        ) {
            return ['Dashboard', 'Process Loan', 'Reports'].includes(tab.title);
        }
        if (
            userData?.roleCode === 'FEDLRA' &&
            userData?.appLevelRole?.includes('LR-CHECKER') &&
            selectedProduct !== 'AGRI_LOAN'
        ) {
            return tab.title === 'Loan Renewal (Checker)';
        }
        if (
            userData?.appLevelRole?.includes('LR-MAKER') &&
            !userData?.appLevelRole?.includes('LR-CHECKER')
        ) {
            return tab.title === 'Loan Renewal (Maker)';
        }

        return (
            isAuthenticated(
                tab.title.includes('Loan Renewal')
                    ? userData?.appLevelRole[0]
                    : userData?.roleCode,
                tab.role
            ) &&
            (!tab.productSpecific || tab.productSpecific[selectedProduct] === true)
        );
    });

    useEffect(() => {
        if (
            !mainRoute.includes(routeConstant.LOGIN) &&
            !mainRoute.includes(routeConstant.PRODUCT_SELECTION)
        ) {
            let foundMatch = false;

            // First, try to find exact path match
            filteredTabs.forEach((tab, index) => {
                if (mainRoute === tab.path) {
                    setValueIndex(index);
                    foundMatch = true;
                    return;
                }
            });

            // If no exact match, check for partial matches
            if (!foundMatch) {
                filteredTabs.forEach((tab, index) => {
                    if (tab.path !== '/' && mainRoute?.includes(tab.path)) {
                        setValueIndex(index);
                        foundMatch = true;
                    }
                });
            }

            // If still no match, apply product-specific defaults
            if (!foundMatch) {
                if (
                    selectedProduct === 'AGRI_LOAN' &&
                    userData?.appLevelRole?.includes('LR-CHECKER')
                ) {
                    const checkerTabIndex = filteredTabs.findIndex(
                        (tab) => tab.title === 'Loan Renewal (Checker)'
                    );

                    if (checkerTabIndex !== -1) {
                        setValueIndex(checkerTabIndex);
                        navigate(loanRenewalCheckerPath);
                        dispatch(navigatePath(loanRenewalCheckerPath));
                    }
                } else if (selectedProduct === 'PRAGATI_TERM_LOAN') {
                    const dashboardIndex = filteredTabs.findIndex(
                        (tab) => tab.title === 'Dashboard'
                    );

                    if (dashboardIndex !== -1) {
                        setValueIndex(dashboardIndex);
                        navigate(`/${routeConstant.DASHBOARD}`);
                        dispatch(navigatePath(`/${routeConstant.DASHBOARD}`));
                    }
                } else if (filteredTabs.length > 0) {
                    // Default case - select first tab
                    setValueIndex(0);
                }
            }
        }
    }, [
        userData,
        mainRoute,
        selectedProduct,
        filteredTabs,
        loanRenewalPath,
        loanRenewalCheckerPath,
        navigate,
        dispatch,
    ]);

    // Add this effect to debug and fix reportPath issues
    useEffect(() => {
        console.log('Current reportPath:', reportPath);
        console.log('Current product:', selectedProduct);

        if (!reportPath && selectedProduct) {
            dispatch(
                reportsPath(
                    `/${routeConstant.REPORTS}/${routeConstant.PROSPECT_REPORT}`
                )
            );

            console.log('Report path is invalid, setting default');
        }
    }, [reportPath, selectedProduct, dispatch]);

    console.log(
        'Tab paths:',
        filteredTabs.map((tab) => ({ title: tab.title, path: tab.path }))
    );

    return (
        <div className="sidebar-wrapper">
            <Tabs
                orientation="vertical"
                variant="scrollable"
                scrollButtons={false}
                value={valueIndex}
                aria-label="Vertical tabs"
                TabIndicatorProps={{ style: customIndicatorStyle }}
            >
                {filteredTabs?.map(
                    (tab, index) =>
                        isAuthenticated(
                            tab.title === 'Loan Renewal'
                                ? userData?.appLevelRole[0]
                                : userData?.roleCode,
                            tab.role
                        ) && (
                            <Tooltip
                                key={index}
                                title={tab.title}
                                placement="right"
                                arrow
                            >
                                <Tab
                                    icon={<div style={tabDiv2}>{tab.icon}</div>}
                                    sx={tabStyle}
                                    aria-label={tab.title}
                                    disableRipple
                                    onClick={() => {
                                        let pathToNavigate = tab.path;

                                        // if (mainRoute !== tab.path) {
                                        if (tab.title === 'Loan Renewal (Checker)') {
                                            pathToNavigate =
                                                loanRenewalCheckerPath ||
                                                `/loan-renewal-checker/${routeConstant.LOAN_RENEWAL_DETAILS_CHECKER}`;
                                        } else if (
                                            tab.title === 'Loan Renewal (Maker)'
                                        ) {
                                            pathToNavigate =
                                                loanRenewalPath ||
                                                `/loan-renewal/${routeConstant.LOAN_RENEWAL_DETAILS}`;
                                        } else if (tab.title === 'Reports') {
                                            pathToNavigate =
                                                reportPath ||
                                                `/${routeConstant.REPORTS}/${routeConstant.PROSPECT_REPORT}`;
                                            console.log(
                                                `Reports path resolved to: ${pathToNavigate}`
                                            );
                                        }

                                        console.log(
                                            `Attempting to navigate to: ${pathToNavigate} for tab: ${tab.title}`
                                        );

                                        navigate(pathToNavigate, { replace: true });
                                        dispatch(navigatePath(pathToNavigate));
                                        // dispatch(navigatePath(tab.path));
                                        dispatch(resetPagination());
                                        dispatch(setSelectedCustomer(null));
                                        dispatch(filterChipsClearAll());
                                        dispatch(clearGroupListData());
                                        dispatch(updatePayloadWithPath({}));
                                        setValueIndex(index);
                                        // }
                                    }}
                                />
                            </Tooltip>
                        )
                )}
            </Tabs>
        </div>
    );
});

Sidebar.displayName = 'Sidebar';
export default Sidebar;
