import React from 'react';

import { SearchOff } from '@mui/icons-material';
import { Box, Fade, keyframes, Slide, styled, Typography } from '@mui/material';

import PropTypes from 'prop-types';

const pulseKeyframe = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const PulsingBox = styled(Box)({
    animation: `${pulseKeyframe} 2s infinite ease-in-out`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const NoDataCard = ({ customStyle }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '200px',
                width: '100%',
                p: 4,
                ...customStyle,
            }}
        >
            <Slide in={true} direction="down" timeout={1000}>
                <PulsingBox sx={{ mb: 3 }}>
                    <SearchOff
                        sx={{
                            fontSize: 60,
                            color: 'primary.main',
                        }}
                    />
                </PulsingBox>
            </Slide>

            <Fade in={true} timeout={800} style={{ transitionDelay: '500ms' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        No Data Available
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        We couldn&apos;t find any data to display at this moment
                    </Typography>
                </Box>
            </Fade>
        </Box>
    );
};

NoDataCard.propTypes = {
    customStyle: PropTypes.object,
};

NoDataCard.defaultProps = {
    customStyle: {},
};

export default NoDataCard;
