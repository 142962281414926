import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import { showSnackBar } from '../../redux/commonSlice';
import { selectSnackBar } from '../../redux/selector';
import Alert from './Alert';

const snackBarCloseState = {
    snackBarOpen: false,
    allowClose: true,
    vertical: 'top',
    horizontal: 'right',
    duration: 3000,
    severity: 'info',
    text: '',
    color: '',
};

const CustomSnackbar = () => {
    const dispatch = useDispatch();
    const {
        allowClose,
        color,
        duration,
        horizontal = 'right',
        severity,
        snackBarOpen,
        snackBarMessage,
        snackBarMessageKey,
        vertical = 'top',
    } = useSelector(selectSnackBar);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        if (allowClose || reason === 'timeout') {
            dispatch(showSnackBar(snackBarCloseState));
        }
    };

    const renderSnackBarMessage = () => {
        if (snackBarMessageKey === 'otp_warning') {
            return (
                <Box display="flex" alignItems="center">
                    <WarningAmberIcon sx={{ mr: 1 }} />
                    OTP expired. Please request a new one.
                </Box>
            );
        }
        return snackBarMessage; // Default behavior
    };

    return (
        <Box>
            {snackBarOpen && (
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={duration}
                    onClose={handleClose}
                    anchorOrigin={{ vertical, horizontal }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: '300px', backgroundColor: color || 'none' }}
                    >
                        {renderSnackBarMessage()}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default CustomSnackbar;
