import * as React from 'react';
const PTLHeaderIcon = (props) => (
    <svg
        width={29}
        height={29}
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M27 8.25L14.5 2L2 8.25V20.75L14.5 27L27 20.75V8.25Z"
            stroke="#01468E"
            strokeWidth={3}
            strokeLinejoin="round"
        />
        <path
            d="M2 8.25L14.5 14.5"
            stroke="#01468E"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.5 27V14.5"
            stroke="#01468E"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27 8.25L14.5 14.5"
            stroke="#01468E"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.75 5.125L8.25 11.375"
            stroke="#01468E"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default PTLHeaderIcon;
