/* eslint-disable no-console */
import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import jsPDF from 'jspdf';

import { selectSelectedProduct } from '../features/login/redux/selector';
import { store } from '../store';
import { decrypt, encrypt } from './CryptographyService';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const secureMode = process.env.REACT_APP_SECURE_MODE;
const instance = axios.create();
const apiService = setupCache(instance, {
    storage: buildWebStorage(sessionStorage, 'axios-cache:'),
    methods: ['get', 'post'],
    ttl: 30000,
});

const encryptRequestBody = (data) => {
    const requestPayload = { payload: encrypt(data?.payload) };
    return requestPayload;
};

const addHeadersInterceptor = (config) => {
    const accToken = window.sessionStorage.getItem('aT');
    const refToken = window.sessionStorage.getItem('rT');
    let partnerId = window.sessionStorage.getItem('partnerId');

    const state = store.getState();
    const selectedProduct = selectSelectedProduct(state);

    if (selectedProduct === 'PRAGATI_TERM_LOAN') {
        partnerId = 'SH-PRG';
    } else if (partnerId !== 'SH-PRG' && partnerId !== 'SH-FED') {
        // If partnerId exists and is not one of the default values, keep it as is
        // This means we're using the custom partnerId from login response
    } else {
        partnerId = 'SH-FED';
    }

    // const partnerId = window.sessionStorage.getItem('partnerId');
    const authConfig = {
        ...config,
        headers: {
            ...config.headers,
            // 'partner-id': partnerId,
        },
    };

    const token = config.url === '/auth/v2/auth/logout' ? refToken : accToken;
    if (token) {
        authConfig.headers.Authorization = `Bearer ${token}`;
        authConfig.headers['partner-id'] = partnerId;
    }
    return authConfig;
};

const encryptRequestBodyInterceptor = (config) => {
    if (secureMode && config.method === 'post') {
        config.data = encryptRequestBody(config.data);
    }
    return config;
};

// export const generatePDFFromHTML = async (htmlContent, fileName, password) => {
//     try {
//         if (!htmlContent) {
//             throw new Error('HTML content is empty');
//         }

//         // Create a new jsPDF document
//         const pdf = new jsPDF({
//             orientation: 'p',
//             unit: 'mm',
//             format: 'a4',
//             encryption: {
//                 userPassword: password,
//                 ownerPassword: password,
//                 userPermissions: ['print', 'read'],
//             },
//         });

//         // Create a temporary div to render HTML
//         const tempDiv = document.createElement('div');
//         tempDiv.innerHTML = htmlContent.trim();
//         document.body.appendChild(tempDiv);

//         return new Promise((resolve, reject) => {
//             pdf.html(tempDiv, {
//                 callback: function (doc) {
//                     try {
//                         // Remove temporary div
//                         document.body.removeChild(tempDiv);

//                         // Save the password-protected PDF
//                         doc.save(fileName);
//                         resolve(true);
//                     } catch (error) {
//                         console.error('Error in PDF generation:', error);
//                         reject(error);
//                     }
//                 },
//                 x: 10,
//                 y: 10,
//                 width: 190,
//                 windowWidth: 1024,
//             });
//         });
//     } catch (error) {
//         console.error('Error generating PDF:', error);
//         return false;
//     }
// };
export const generatePDFFromHTML = async (htmlContent, fileName) => {
    try {
        if (!htmlContent) {
            throw new Error('HTML content is empty');
        }

        const pdf = new jsPDF('p', 'mm', 'a4');

        pdf.html(htmlContent, {
            callback: function (pdf) {
                pdf.save(fileName);
            },
            x: 10,
            y: 10,
            width: 190,
            windowWidth: 1024,
        });

        return true;
    } catch (error) {
        console.error('Error generating PDF:', error);
        return false;
    }
};

apiService.interceptors.request.use(addHeadersInterceptor);
apiService.interceptors.request.use(encryptRequestBodyInterceptor);

apiService.interceptors.response.use(
    (response) => {
        const responseType = response.config.responseType;
        if (responseType === 'blob') {
            return response;
        }
        if (secureMode) {
            return JSON.parse(decrypt(response?.data?.payload));
        }
        return response?.data?.payload;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.sessionStorage.clear();
            window.location.href = '/login';
            window.localStorage.setItem('API_error_with_401', JSON.stringify(error));
        }
        return Promise.reject(error);
    }
);

export default apiService;
