/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Chip, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Box, width } from '@mui/system';

import AgriHeaderIcon from '../assets/svg/AgriHeaderIcon';
import PTLHeaderIcon from '../assets/svg/PTLHeaderIcon';
import {
    selectSelectedProduct,
    selectUserDetails,
} from '../features/login/redux/selector';
import {
    cleardata,
    clearDataExceptLoginResponse,
} from '../features/login/redux/slice';
import { logoutApi } from '../features/login/redux/thunks';
import { showSnackBar } from '../redux/commonSlice';
import { selectLifeCycleMasterLevel } from '../redux/selector';
import { clearGlobaldata, navigatePath } from '../redux/slice';
import { getCustomerLifecycleMasterLevel } from '../redux/thunks';
import { routeConstant } from '../routes/routeConstant';
import { theme } from '../theme';
import Notification from './Notification';

const buttonContainer = {
    minWidth: 'max-content',
    maxWidth: '8rem',
    backgroundColor: theme.palette.primary.light,
    p: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    borderRadius: 1,
    cursor: 'pointer',
    padding: '0.3rem 0.5rem',
};

const productChipStyle = {
    marginRight: '1rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: '800',
    height: '35px',
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
};

const logoStyle = {
    width: '20px',
    height: '20px',
    marginRight: '-2px',
    color: theme.palette.primary.main,
};

const menuItemLogoContainerStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '4px',
    marginRight: '4px',
};

const clickableMenuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
};

const userTextStyle = {
    fontSize: '1.1rem',
    maxWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'Capitalize',
    userSelect: 'none',
    color: theme.palette.common.superBlack,
    fontWeight: '600',
};
const headerWrapperStyle = {
    width: 'max-content',
    marginRight: '2.5rem',
    display: 'flex',
    justifyContent: 'right',
    height: '35px',
};
const userNameWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
};

const HeaderPopper = () => {
    const { userInfo, userId, logoutResponse } = useSelector(selectUserDetails);
    const { customerLifecycle } = useSelector(selectLifeCycleMasterLevel);
    const selectedProduct = useSelector(selectSelectedProduct);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const ellipsisRef = React.useRef();
    const [showTooltip, setShowTooltip] = React.useState(false);

    const formatProductName = (productCode) => {
        if (!productCode) return '';

        // Replace underscores with spaces and convert to title case
        const formatted = productCode
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        return formatted;
    };

    const getProductLogo = (productCode) => {
        if (!productCode) return null;

        if (productCode === 'PRAGATI_TERM_LOAN') {
            return <PTLHeaderIcon style={logoStyle} selected={true} />;
        } else if (productCode === 'AGRI_LOAN') {
            return <AgriHeaderIcon style={logoStyle} selected={true} />;
        }
        return null;
    };

    const getMenuItemProductLogo = (productCode) => {
        if (!productCode) return null;

        if (productCode === 'PRAGATI_TERM_LOAN') {
            return (
                <Box sx={menuItemLogoContainerStyle}>
                    <PTLHeaderIcon style={logoStyle} selected={true} />
                </Box>
            );
        } else if (productCode === 'AGRI_LOAN') {
            return (
                <Box sx={menuItemLogoContainerStyle}>
                    <AgriHeaderIcon style={logoStyle} selected={true} />
                </Box>
            );
        }
        return null;
    };

    useEffect(() => {
        if (logoutResponse && logoutResponse?.code === 'success') {
            navigate(`/${routeConstant.LOGIN}`);

            dispatch(
                showSnackBar({
                    snackBarOpen: true,
                    allowClose: true,
                    vertical: 'top',
                    horizontal: 'right',
                    duration: 1000,
                    severity: 'success',
                    snackBarMessage: logoutResponse?.message,
                    color: theme.palette.common.primaryGreen,
                })
            );
        } else if (logoutResponse?.code === 'failure') {
            navigate(`/${routeConstant.LOGIN}`);
        }
    }, [logoutResponse]);

    useEffect(() => {
        const container = ellipsisRef.current;

        if (container?.scrollWidth > container?.clientWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [userInfo?.userName]);

    useEffect(() => {
        if (Object.keys(customerLifecycle ?? {}).length === 0) {
            dispatch(getCustomerLifecycleMasterLevel());
        }
    }, []);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        dispatch(logoutApi());
    };
    const handleChangePassword = () => {
        navigate(`/${routeConstant.LOGIN}/${routeConstant.SET_PASSWORD}`);
    };
    const handleProductClick = () => {
        handleClose();
        const roleCode = userInfo?.roleCode;
        const appLevelRole = userInfo?.appLevelRole;
        dispatch(clearGlobaldata());
        dispatch(clearDataExceptLoginResponse());
        dispatch(navigatePath(''));

        if (selectedProduct) {
            localStorage.setItem('previous_selected_product', selectedProduct);
        }

        navigate(`/${routeConstant.LOGIN}/${routeConstant.PRODUCT_SELECTION}`, {
            replace: true,
        });

        if (roleCode) {
            localStorage.setItem('last_roleCode', roleCode);
        }
        if (appLevelRole) {
            localStorage.setItem('last_appLevelRole', JSON.stringify(appLevelRole));
        }
    };
    return (
        <>
            <Box sx={userNameWrapperStyle}>
                {selectedProduct && (
                    <Tooltip
                        title={`Selected Product: ${formatProductName(
                            selectedProduct
                        )}`}
                        placement="bottom"
                        arrow
                    >
                        <Chip
                            icon={getProductLogo(selectedProduct)}
                            label={formatProductName(selectedProduct)}
                            sx={productChipStyle}
                        />
                    </Tooltip>
                )}
                <Notification userId={userId} />

                <Box sx={headerWrapperStyle}>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ marginTop: '0.5rem' }}
                    >
                        <MenuItem style={{ pointerEvents: 'none' }}>
                            {userId}
                        </MenuItem>
                        <MenuItem style={{ pointerEvents: 'none' }}>
                            {userInfo?.role}
                        </MenuItem>
                        <MenuItem
                            onClick={handleProductClick}
                            sx={clickableMenuItemStyle}
                        >
                            Change Product
                        </MenuItem>
                        <MenuItem onClick={handleChangePassword}>
                            Change Password
                        </MenuItem>
                        <MenuItem
                            style={{ color: theme.palette.common.negativeRed }}
                            onClick={handleLogout}
                        >
                            Logout
                        </MenuItem>
                    </Menu>
                    <Tooltip
                        title={showTooltip ? userInfo?.userName : ''}
                        placement="bottom"
                        arrow
                    >
                        <Box sx={buttonContainer} onClick={handleClick}>
                            <Box>
                                <AccountCircleIcon sx={{ marginTop: '3px' }} />
                            </Box>
                            <Typography sx={userTextStyle} ref={ellipsisRef}>
                                {userInfo?.userName}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </>
    );
};

export default HeaderPopper;
