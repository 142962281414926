/* eslint-disable no-console */
import { createSelector } from '@reduxjs/toolkit';

const login = (state) => state?.login;
export const userInfo = createSelector([login], (state) => state?.userInfo);
export const selectLoginData = createSelector([login], (state) => state?.data);

export const selectLoading = createSelector([login], (state) => state?.loading);
export const selectupdatePasscodeResponse = createSelector(
    [login],
    (state) => state?.setPass
);
export const selectUserDetails = createSelector([login], (state) => state);
export const selectUserInfo = createSelector([login], (state) => state.userInfo);

export const selectUserId = createSelector([login], (state) => state.userId);

export const selectAppLevelRole = createSelector(
    [login],
    (state) => state.appLevelRole
);

export const selectMobileNumber = createSelector(
    [login],
    (state) => state?.userInfo?.mobileNumber
);
export const selectIsOtpRequired = createSelector(
    [login],
    (state) => state.isOtpRequired
);
export const selectIsOtpNavigationPending = createSelector(
    [login],
    (state) => state.isOtpNavigationPending
);
export const selectBackToLoginClicks = createSelector([login], (state) => {
    console.log('Selecting backToLoginClicks:', state?.backToLoginClicks);
    return state?.backToLoginClicks || 0;
});
export const selectProductIds = createSelector(
    [login],
    (state) => state?.userInfo?.productId || []
);
export const selectSelectedProduct = createSelector([login], (state) => {
    const selectedProduct = state?.selectedProduct;
    console.log('Selected Product in Selector:', selectedProduct);
    return selectedProduct;
});
