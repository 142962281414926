/* eslint-disable no-console */
import { createAction, createSlice } from '@reduxjs/toolkit';

import { routeConstant } from '../../../routes/routeConstant';
import { loginUser, logoutApi, sendOTP, setPassword, verifyOTP } from './thunks';
export const clearDataExceptLoginResponse = createAction(
    'login/clearDataExceptLoginResponse'
);

const initialState = {
    value: 0,
    data: null,
    userInfo: {
        lastLoggedIn: '',
        partnerId: '',
        productId: '',
        role: '',
        roleCode: '',
        userName: '',
        profileImage: '',
        appLevelRole: [],
        mobileNumber: '',
    },
    setPass: null,
    userId: null,
    logoutResponse: null,
    otpResponse: null,
    verifyOTPResponse: null,
    isOtpRequired: true,
    isOtpNavigationPending: false,
    userBlocked: false,
    twoFaVerified: false,
    backToLoginClicks: 0,
    selectedProduct: null,
    availableProducts: [],
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        cleardata(state) {
            console.log(
                'Clear data called, current click count:',
                state.backToLoginClicks
            );

            const currentClickCount = state.backToLoginClicks;
            state.data = null;
            state.setPass = null;
            state.userInfo = null;
            state.userId = null;
            state.otpResponse = null;
            state.verifyOTPResponse = null;
            state.isOtpRequired = true;
            state.isOtpNavigationPending = false;
            state.backToLoginClicks = currentClickCount;
            state.selectedProduct = null;
            console.log('After clear data, click count:', state.backToLoginClicks);
        },

        setOtpNavigationPending(state, action) {
            state.isOtpNavigationPending = action.payload;
        },
        storeValues(state, action) {
            state.userId = action?.payload?.employeeId;
        },
        setOtpRequired(state, action) {
            state.isOtpRequired = action.payload;
        },
        setSelectedProduct(state, action) {
            state.selectedProduct = action.payload;
            // Update paths based on the selected product
            if (action.payload === 'AGRI_LOAN') {
                state.loanRenewalPath = `/loan-renewal/${routeConstant.LOAN_RENEWAL_DETAILS}`;
                state.loanRenewalCheckerPath = `/loan-renewal-checker/${routeConstant.LOAN_RENEWAL_DETAILS_CHECKER}`;
            } else {
                state.loanRenewalPath = '';
                state.loanRenewalCheckerPath = '';
            }
        },
        incrementBackToLoginClicks(state) {
            console.log('Previous count:', state.backToLoginClicks);

            state.backToLoginClicks = state.backToLoginClicks + 1;
            console.log('New count:', state.backToLoginClicks);

            localStorage.setItem('backToLoginClicks', state.backToLoginClicks);
        },
        resetBackToLoginClicks(state) {
            state.backToLoginClicks = 0;
            localStorage.removeItem('backToLoginClicks');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                const LoginResponse = action.payload;
                const modifiedPayload = JSON.parse(JSON.stringify(LoginResponse));

                delete modifiedPayload?.data?.accessToken;
                delete modifiedPayload?.data?.refreshToken;

                state.data = modifiedPayload;
                state.logoutResponse = null;
                const info = action?.payload?.data;

                if (info) {
                    state.userInfo = {
                        lastLoggedIn: info.lastLoggedIn,
                        partnerId: info.partnerId,
                        productId: info.productId,
                        role: info.role,
                        roleCode: info.roleCode,
                        userName: info.userName,
                        profileImage: info.profileImage,
                        appLevelRole: info?.appLevelRole,
                        mobileNumber: info?.mobileNumber,
                        userBlocked: info?.userBlocked || false,
                        twoFaVerified: info?.twoFaVerified || false,
                    };

                    if (info.availableProducts) {
                        state.availableProducts = info.availableProducts;
                    }

                    localStorage.setItem('roleCode', info.roleCode);
                    localStorage.setItem(
                        'appLevelRole',
                        JSON.stringify(info.appLevelRole)
                    );
                }

                if (
                    action.payload?.data?.twoFaLogin === true &&
                    !action.payload?.data?.userBlocked &&
                    !action.payload?.data?.twoFaVerified
                ) {
                    state.isOtpNavigationPending = true;
                }
            })
            .addCase(setPassword.fulfilled, (state, action) => {
                state.setPass = action.payload;
            })
            .addCase(logoutApi.fulfilled, (state, action) => {
                state.logoutResponse = action.payload;
            })
            .addCase(sendOTP.fulfilled, (state, action) => {
                state.otpResponse = action.payload;
                state.isOtpNavigationPending = action.payload?.code === 'success';
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.verifyOTPResponse = action.payload;
                state.isOtpNavigationPending = false;
            })
            .addCase(clearDataExceptLoginResponse, (state) => {
                // Store the data we want to preserve
                const userInfo = { ...state.userInfo };
                const data = state.data;
                const userId = state.userId;
                const availableProducts = [...state.availableProducts];
                const currentClickCount = state.backToLoginClicks;

                // Reset the state
                Object.assign(state, initialState);

                // Restore the preserved data
                state.userInfo = userInfo;
                state.data = data;
                state.userId = userId;
                state.availableProducts = availableProducts;
                state.backToLoginClicks = currentClickCount;

                state.loanRenewalPath = '';
                state.loanRenewalCheckerPath = '';
            })
            .addCase('login/CLEAR_STATE', () => {
                return initialState;
            })
            .addCase('DESTROY_SESSION', (state) => {
                // Preserve backToLoginClicks when session is destroyed
                const clicks = state.backToLoginClicks;
                return {
                    ...initialState,
                    backToLoginClicks: clicks,
                };
            });
    },
});

export const {
    cleardata,
    storeValues,
    setOtpRequired,
    setOtpNavigationPending,
    incrementBackToLoginClicks,
    resetBackToLoginClicks,
    setSelectedProduct,
} = loginSlice.actions;
export default loginSlice.reducer;
