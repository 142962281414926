/* eslint-disable no-console */
// thunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';
export const loginUser = createAsyncThunk(
    'DATA_FETCH/LOGIN',
    async (values, { rejectWithValue }) => {
        const credData = { payload: { ...values } };
        const header = {
            'device-type': 'WEB',
        };
        try {
            const response = await apiService.post('/auth/v2/auth/login', credData, {
                headers: header,
                cache: false,
            });

            window.sessionStorage.setItem('partnerId', response?.data?.partnerId);
            window.sessionStorage.setItem('aT', response?.data?.accessToken);
            window.sessionStorage.setItem('rT', response?.data?.refreshToken);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const setPassword = createAsyncThunk(
    'DATA_FETCH/SET_PASSWORD',
    async (values, { rejectWithValue }) => {
        const newPasswordData = { payload: { ...values } };
        try {
            const response = await apiService.put(
                '/auth/v2/auth/password/update',
                newPasswordData
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const logoutApi = createAsyncThunk(
    'LOGOUT',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get('/auth/v2/auth/logout', {
                cache: false,
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const sendOTP = createAsyncThunk(
    'DATA_FETCH/SEND_OTP',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const mobileNumber = state.login?.userInfo?.mobileNumber;

            if (!mobileNumber) {
                throw new Error('Mobile number not found in login response');
            }

            const formattedNumber = mobileNumber
                .replace(/^(\+91|91)/, '')
                .replace(/^0+/, '');
            const numberWithCountryCode = `91${formattedNumber}`;

            const otpData = {
                mobileNumber: numberWithCountryCode,
                templateId: '2fa_login',
                customerId: 'unknown',
            };

            const response = await apiService.post(
                '/bank/api/v1/sms/send/otp',
                otpData,
                {
                    cache: false,
                }
            );

            if (response?.payload?.code === 'failure') {
                return rejectWithValue(response.payload);
            }

            return response;
        } catch (error) {
            if (error.response?.data) {
                return rejectWithValue(
                    error.response.data.payload || error.response.data
                );
            }
            return rejectWithValue({
                code: 'failure',
                message: error.message || 'Failed to send OTP',
            });
        }
    }
);
export const verifyOTP = createAsyncThunk(
    'DATA_FETCH/VERIFY_OTP',
    async (otp, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const mobileNumber = state.login?.userInfo?.mobileNumber;

            if (!mobileNumber) {
                throw new Error('Mobile number not found in login response');
            }

            const formattedNumber = mobileNumber
                .replace(/^(\+91|91)/, '')
                .replace(/^0+/, '');
            const numberWithCountryCode = `91${formattedNumber}`;

            const verifyData = {
                mobileNumber: numberWithCountryCode,
                templateId: '2fa_login',
                customerId: 'unknown',
                otp: parseInt(otp),
            };
            const header = {
                'device-type': 'WEB',
            };
            const response = await apiService.post(
                '/bank/api/v1/sms/verify/otp',
                verifyData,
                {
                    headers: header,
                    cache: false,
                }
            );
            return response;
        } catch (error) {
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                code: 'failure',
                message: error.message || 'Verification failed',
            });
        }
    }
);
